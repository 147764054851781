document.addEventListener("DOMContentLoaded", function() {
  const passwordView = document.querySelectorAll(".password_view");
  passwordView.forEach(function(target) {
    target.addEventListener("click", function() {
      const passwordInput = target.closest(".password_wrapper").querySelector("input");
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
      } else {
        passwordInput.type = "password";
      }
    });
  });
})
